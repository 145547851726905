import { useQuery, useMutation, useQueryClient } from 'react-query'
import api, { callApi } from 'utils/api'

export interface GlobalPerson {
  uuid: string
  first_name: string
  last_name: string
  full_name: string
  headline?: string
  linkedin_url?: string
  image_url?: string
  verified: boolean
  connection_type?: 'linkedin' | 'google_contact'
  email?: string
  company_name?: string
}

export const useGlobalPerson = (slug: string) => {
  return useQuery<GlobalPerson>(
    ['globalPerson', slug],
    () => callApi(api.getGlobalPerson, { slug }),
    {
      enabled: !!slug,
    }
  )
}

interface UpdateGlobalPersonData {
  first_name?: string
  last_name?: string
  headline?: string
  linkedin_url?: string
  email?: string
  company_name?: string
  image_url?: string
}

export const useUpdateGlobalPerson = (slug: string) => {
  const queryClient = useQueryClient()

  return useMutation<GlobalPerson, Error, UpdateGlobalPersonData>(
    (updateData) => callApi(api.updateGlobalPerson, { slug, updateData }),
    {
      onMutate: async (newData) => {
        await queryClient.cancelQueries(['globalPerson', slug])
        const previousData = queryClient.getQueryData<GlobalPerson>(['globalPerson', slug])

        queryClient.setQueryData<GlobalPerson>(['globalPerson', slug], old => ({
          ...old!,
          ...newData,
        }))

        return { previousData }
      },
      onError: (err, newData, context) => {
        queryClient.setQueryData(['globalPerson', slug], context?.previousData)
      },
      onSettled: () => {
        queryClient.invalidateQueries(['globalPerson', slug])
      },
    }
  )
} 