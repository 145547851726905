import { GlobalPerson } from "app/javascript/hooks/useGlobalPerson"
import axios from "axios"
import { IEducation, IExperience } from "containers/GlobalUserProfile/types"
import { CandidateProfile, PersonBlueprint } from "utils/types"
import { INote } from "containers/GlobalUserProfile/types"

export default {
  getGlobalPerson: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}`)
  },
  updateGlobalPerson: (params: { slug: string, updateData: Partial<PersonBlueprint> }) => {
    return axios.patch(`/api/v2/global_person/${params.slug}`, {
      global_person: params.updateData
    })
  },
  getCandidateProfile: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}/candidate_profile`)
  },
  updateGlobalPersonCandidateProfile: (params: { slug: string, updateData: Partial<CandidateProfile> }) => {
    return axios.patch(`/api/v2/global_person/${params.slug}/candidate_profile`, {
      candidate_profile: params.updateData
    })
  },
  getWorkExperiences: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}/work_experiences`)
  },
  updateWorkExperience: (params: { 
    slug: string, 
    updateData: IExperience[]
  }) => {
    return axios.post(`/api/v2/global_person/${params.slug}/work_experiences`, 
      { work_experience: params.updateData }
    )
  },
  getEducations: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}/educations`)
  },
  updateEducation: (params: { 
    slug: string, 
    updateData: IEducation[]
  }) => {
    return axios.post(`/api/v2/global_person/${params.slug}/educations`, 
      { education: params.updateData }
    )
  },
  getNotes: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}/notes`)
  },
  createNote: (params: { 
    slug: string, 
    note: INote
  }) => {
    return axios.post(`/api/v2/global_person/${params.slug}/notes`, 
      { note: params.note }
    )
  },
  getFiles: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}/files`)
  },
  uploadFile: (params: { 
    slug: string, 
    upload_uuid: string
  }) => {
    return axios.post(
      `/api/v2/global_person/${params.slug}/files`,
      { upload_uuid: params.upload_uuid }
    )
  },
  getGlobalPersonAbout: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}/about`)
  },
  updateGlobalPersonAbout: (params: { 
    slug: string, 
    about: {
      about?: string;
      location?: string;
      function?: string;
      level?: string;
    }
  }) => {
    return axios.patch(
      `/api/v2/global_person/${params.slug}/about`,
      { about: params.about }
    )
  },
  getGlobalPersonPermissions: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}/permissions`)
  },
  getConnectionStatus: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}/connection`)
  },
  createConnection: (params: { slug: string }) => {
    return axios.post(`/api/v2/global_person/${params.slug}/connection`)
  },
  deleteConnection: (params: { slug: string }) => {
    return axios.delete(`/api/v2/global_person/${params.slug}/connection`)
  },
  getGlobalPersonLists: (params: { slug: string }) => {
    return axios.get(`/api/v2/global_person/${params.slug}/lists`)
  },
  inviteGlobalPerson: (params: {
    global_person_uuid: string
    note: string
    email: string
  }) =>
    axios.post(
      `/api/v2/global_person/${params.global_person_uuid}/invite`,
      params
    )
}