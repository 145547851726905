import React, { useState, useEffect } from 'react'
import { useUpdateCandidateProfileAbout } from '../../../hooks/useGlobalPersonCandidateProfile'

import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import {
  LEVEL_OPTIONS,
  ROLE_FUNCTION_OPTIONS,

} from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { Select, TextArea } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'

export type IAboutOptions = {
  function: string
  location: string
  level: string
  about: string
}

interface AboutModalProps {
  onHide: () => void
  options: IAboutOptions
  slug: string
}

const AboutModal = ({ onHide, options, slug }: AboutModalProps) => {

  const [aboutOptions, setAboutOptions] = useState<IAboutOptions>(options)
  const updateProfile = useUpdateCandidateProfileAbout(slug)

  const handleSubmit = async () => {
    try {
      await updateProfile.mutateAsync({
        about: aboutOptions.about,
        location: aboutOptions.location,
        level: aboutOptions.level,
        function: aboutOptions.function,
      })
      onHide()
    } catch (error) {
      console.error('Error updating profile:', error)
    }
  }

  return (
    <Modal
      header="About"
      show={true}
      onHide={onHide}
      dangerouslyBypassFocusLock={true}
      rightActions={
        <CabalButton
          type="button"
          variant="primary"
          onClick={handleSubmit}
          working={updateProfile.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>Function</ModalInputLabel>
        <SelectWrapper>
          <Select
            placeholder="Primary job function(s)"
            value={aboutOptions.function}
            onChange={(e) => setAboutOptions({ ...aboutOptions, function: e })}
            options={ROLE_FUNCTION_OPTIONS}
          />
        </SelectWrapper>
      </ModalInputWrapper>
      <ModalInputWrapper className="py-2">
        <ModalInputLabel>Level</ModalInputLabel>
        <SelectWrapper>
          <Select
            placeholder="Experience level"
            value={aboutOptions.level}
            onChange={(e) => setAboutOptions({ ...aboutOptions, level: e })}
            options={LEVEL_OPTIONS}
          />
        </SelectWrapper>
      </ModalInputWrapper>


      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>Location</ModalInputLabel>
        <CityInput
          onChange={(opt) => {
            setAboutOptions({ ...aboutOptions, location: opt?.value })
          }}
          value={{ value: aboutOptions.location, label: aboutOptions.location }}
        />
      </ModalInputWrapper>

      <div className="mb-4">
        <ModalInputWrapper className="py-2">
          <ModalInputLabel>Bio</ModalInputLabel>
          <TextArea
            placeholder="Add a one-line bio"
            value={aboutOptions.about}
            onChange={(e) => setAboutOptions({ ...aboutOptions, about: e.target.value })}
            rows={6}
          />
        </ModalInputWrapper>
      </div>
    </Modal>
  )
}

export default AboutModal
