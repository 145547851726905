import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import { useMutation, useQuery } from 'react-query'
import { useSetState } from 'react-use'

import FacilitatedBy from 'containers/IntroRequestModal/components/FacilitatedBy'
import UsersInfo from 'containers/IntroRequestModal/components/UsersInfo'
import {
  IRCompanyListObject,
  IRPayload,
  IRequestor,
  ISalesTarget,
} from 'containers/IntroRequestModal/types'
import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal, { useModal } from 'global/Modal'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import PillV2 from 'ui-components/PillV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { setJobIntroRequestCookie } from 'utils/cookieUtils'
import { absoluteHtmlContent } from 'utils/html'
import { IFacilitateIntroResponse } from 'utils/types/IFacilitateIntro'

interface IProps {
  requestable: ISalesTarget
  companyList: IRCompanyListObject
  facilitator: IntroConnection
  requestor: IRequestor
  networkingOnly?: boolean
  introRequestUuid?: string | null
  facilitatorKey?: string | null
}

interface IList {
  company_list_id: string
  team_slug: string
  note: string
  requestable_id: string
  facilitator_user_uuid: string
  gatekeeper_id: string
  requestable_type: string
  requestor_uuid: string
  request_type: string
  request_reason: string
}

interface IRequestReasons {
  [key: string]: string
}

const icons: { [key: string]: JSX.Element } = {
  sales_partnership: <i className="fa-regular fa-rocket"></i>,
  job: <i className="fa-light fa-star"></i>,
  networking: <i className="fa-light fa-arrows-left-right"></i>,
  other_request_reason: <i className="fa-light fa-brain"></i>,
  fundraising: <i className="fa-regular fa-dollar-sign"></i>,
}

export function CreatePortfolioListModal({
  requestable,
  companyList,
  facilitator,
  requestor,
  facilitatorKey,
  networkingOnly = false,
}: IProps) {
  const { user } = useCurrentUser()
  const teamSlug = companyList?.owning_team?.slug || user?.team?.slug
  const { canViewTeam } = useAccessControl(teamSlug)

  const [disableSendButton, setDisableSendButton] = useState(true)

  const [list, setList] = useSetState<IList>({
    team_slug: teamSlug,
    company_list_id: companyList.uuid,
    note: '',
    requestable_id: requestable.uuid,
    facilitator_user_uuid: !canViewTeam && facilitatorKey ? facilitatorKey : facilitator.uuid,
    gatekeeper_id: facilitator.uuid,
    requestable_type: requestable.item_type,
    requestor_uuid: requestor.uuid,
    request_type: canViewTeam ? 'advisor' : 'non_advisor',
  })

  const { mutate: introRequestMutate } = useMutation({
    mutationFn: (payload: IRPayload) => callApi(api.createIntroRequestV2, payload),
  })

  const { data: requestReasonsOptions, isLoading: requestReasonsFetchLoading } =
    useQuery<IRequestReasons>(['requestReasons'], () =>
      callApi(api.getIntroRequestReasons, companyList.uuid),
    )

  const [selectedReason, setSelectedReason] = useState<string | null>(null)
  const [note, setNote] = useState<string>('')

  const handleReasonClick = (reason: string) => {
    setSelectedReason(reason)
    setList({ ...list, request_reason: reason })
    setDisableSendButton(false)
  }

  const handleNoteChange = (e) => {
    const newNote = absoluteHtmlContent(e) || ''
    setNote(newNote)
    setList({ ...list, note: newNote })
  }

  const { closeModal } = useModal()

  const handleSubmit = () => {
    introRequestMutate(list, {
      onSuccess: (data: IFacilitateIntroResponse) => {
        if (
          data.request_type === 'non_advisor' &&
          (data.requestable_type === 'InvestorCompanyJob' || data.request_reason === 'job')
        ) {
          setJobIntroRequestCookie(data)
        }
        cabalToast({ style: 'success', content: 'Intro requested!', fadeOut: false })
        closeModal('create-portfolio-intro-modal')
      },
      onError: () => {
        console.log('Error')
      },
    })
  }

  return (
    <Modal
      className="max-w-lg"
      show={true}
      onHide={() => closeModal('create-portfolio-intro-modal')}
      header={
        <Typography fontSize="16" fontWeight={600} lineHeight="1.2" className="w-full text-center">
          Request intro to {requestable.name}
        </Typography>
      }
      centerActions={
        <div className="py-2">
          <CabalButton onClick={handleSubmit} disabled={disableSendButton}>
            Send request to {facilitator.name}
          </CabalButton>
        </div>
      }
    >
      {requestReasonsFetchLoading ? (
        <Typography fontSize="16" fontWeight={600} lineHeight="1.2" className="w-full text-center">
          Loading...
        </Typography>
      ) : (
        <>
          <UsersInfo requestor={requestor} target={requestable}></UsersInfo>

          <FacilitatedBy facilitator={facilitator} />

          <div className="flex flex-col gap-6 pt-2">
            <div className="flex flex-col gap-2">
              <Typography fontSize="12" fontWeight={400} lineHeight="1">
                Purpose for request
              </Typography>

              <div className="flex flex-wrap items-center gap-2">
                {requestReasonsOptions &&
                  Object.entries(requestReasonsOptions).map(([key, value], index) => (
                    <PillV2
                      key={index}
                      title={
                        <Typography fontSize="12" lineHeight={1}>
                          {icons[key]} {value}
                        </Typography>
                      }
                      active={value === selectedReason}
                      onClick={() => handleReasonClick(value)}
                      className="px-2 py-1"
                    />
                  ))}
              </div>
            </div>

            {networkingOnly && (
              <div className=" text-center">
                <Typography color={'fog'} fontWeight={500}>
                  <i className="fa fa-info-circle mr-1" />{' '}
                  {requestable?.first_name || requestable.name} is only looking to network
                </Typography>
              </div>
            )}
            <div className="flex flex-col gap-2 pb-1">
              <Typography fontSize="12" fontWeight={400} lineHeight="1">
                Note for {requestable.name}
              </Typography>
              <CKEditor
                value={note}
                onChange={(e) => handleNoteChange(absoluteHtmlContent(e) || '')}
                height={100}
                width={'100%'}
                placeholder="Enter your note here..."
                companySlug={teamSlug}
              />
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}
