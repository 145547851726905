import React, { useState } from 'react'

import styled from 'styled-components'

import EditDetails from 'containers/GlobalUserProfile/Modals/PreferencesModal/EditDetails'
import ShareModal from 'containers/GlobalUserProfile/ShareModal'
import { WidgetTab } from 'containers/ListIndexView/TalentList/PeopleList/AddCandidate'
import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import { useMutation } from 'react-query'

export type IAboutOptions = {
  functions: string[]
  location: string
  level: string
  funding_stage_experience: string[]
  bio: string
  workStatus?: string
  companySize: string[]
  compensation: string
}

const TabWrapper = styled.div``

interface PreferencesModalProps {
  onHide: () => void
  options: IAboutOptions
  defaultTab?: 'visibility' | 'edit'
  personUuid: string
}

const PreferencesModal = ({ onHide, options, defaultTab, personUuid }: PreferencesModalProps) => {
  const [tab, setTab] = useState<'visibility' | 'edit'>(defaultTab || 'edit')

  const [prefOptions, setPrefOptions] = useState<IAboutOptions>(options)

  const updateCandidateProfileMutation = useMutation({
    mutationFn: () => api.updateCandidateProfile({ slug: personUuid, updateData: prefOptions }),
    onSuccess: () => {
      onHide()
    }
  })

  return (
    <Modal
      header="Preferences"
      show={true}
      onHide={onHide}
      dangerouslyBypassFocusLock={true}
      rightActions={
        <CabalButton
          type="button"
          variant="primary"
          onClick={() => updateCandidateProfileMutation.mutate()}
          working={updateCandidateProfileMutation.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      <TabWrapper className="flex gap-8 mt-6">
        {/* <WidgetTab active={tab === 'visibility'} onClick={() => setTab('visibility')}>
          <i className={`far fa-lock mr-1 fa-sm`} /> Visibility
        </WidgetTab> */}
        <WidgetTab active={tab === 'edit'} onClick={() => setTab('edit')}>
          <i className={`far fa-edit mr-1 fa-sm`} /> Edit
        </WidgetTab>
      </TabWrapper>

      <div className="mt-4">
        {/* {tab === 'visibility' && <ShareModal isModal={false} header="Visibility" />} */}
        {tab === 'edit' && <EditDetails options={prefOptions} setPrefOptions={setPrefOptions} />}
      </div>
    </Modal>
  )
}

export default PreferencesModal
