import * as React from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import isArray from 'lodash/isArray'
import styled from 'styled-components'

import Typography from 'global/Typography'

import { joinReactElements } from 'utils/array'

interface Props {
  title: React.ReactNode
  description?: React.ReactNode
  avatar?: React.ReactNode
  titleIcon?: React.ReactNode
  scrollOnHover?: boolean
}

const DefaultAvatar = styled(Typography)`
  background-color: ${({ theme }) => theme.colors.mist};
`

const TitleContainer = styled.div<{ $scrollOnHover?: boolean }>`
  &:hover {
    .title-text {
      ${({ $scrollOnHover }) => $scrollOnHover ? `
        animation: scrollText 8s linear infinite;
        white-space: nowrap;
        display: inline-block;
        width: auto;
      ` : ''}
    }
  }
  flex: 1;
  min-width: 0;
  overflow: hidden;

  .title-text {
    display: block;
    width: 100%;
  }

  @keyframes scrollText {
    0%, 5% {
      transform: translateX(0);
    }
    95%, 100% {
      transform: translateX(-100%);
    }
  }
`

const PrimaryInfo: React.FC<Props> = ({ title, description, titleIcon, avatar, scrollOnHover = false }) => {
  const shouldScroll = React.useMemo(() => {
    if (!scrollOnHover) return false;
    if (typeof title !== 'string') return false;
    return title.length > 34;
  }, [title, scrollOnHover]);

  const descriptionArray = React.useMemo(
    () => compact(description && isArray(description) ? description : [description]),
    [description],
  )
  const descriptionData = (
    <div className={cx('flex items-center truncate')} data-testid="datacard-description">
      {joinReactElements(
        descriptionArray.map((d, i) => (
          <Typography
            fontSize="12"
            lineHeight={'15px'}
            color={'fog'}
            className="truncate"
            key={`${title}-description-element-${i}`}
          >
            {d}
          </Typography>
        )),
        () => (
          <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="mx-1.5">
            {'·'}
          </Typography>
        ),
      )}
    </div>
  )

  return (
    <div className="flex space-x-2.5 truncate">
      {avatar && (
        <div className="self-center flex-shrink-0">
          <DefaultAvatar
            color="fog"
            className="flex items-center justify-center w-8 h-8 rounded-full"
          >
            {avatar}
          </DefaultAvatar>
        </div>
      )}
      <div className="flex justify-center flex-col gap-1 truncate">
        <div className="flex items-center gap-1.5 leading-4">
          <TitleContainer $scrollOnHover={shouldScroll}>
            <Typography 
              fontSize="14" 
              lineHeight={1.2} 
              data-testid="datacard-title"
              className="title-text truncate"
              color="inherit"
            >
              {title as React.ReactNode}
            </Typography>
          </TitleContainer>
          {titleIcon && <div className="flex items-center gap-1.5 truncate">{titleIcon}</div>}
        </div>
        {description && descriptionData}
      </div>
    </div>
  )
}

export default PrimaryInfo
